<template>
  <div>
    <div class="row" style="margin-bottom:10px;">
      <div class="col-lg-12">
        <h5 class="purple-title">Customer Log-In</h5>
      </div>
    </div>
    <form @submit.prevent="handleSubmit">
      <div class="container-fluid">
        <div class="row">
          <label class="col-lg-3" for="login-username">Username:</label>
          <input
            class="col-lg-4 form-control"
            name="username"
            id="login-username"
            type="email"
            required
            v-model="username"
          />
        </div>
        <div class="row" style="margin-top:10px;">
          <label class="col-lg-3" for="login-password">Password:</label>
          <input
            class="col-lg-4 form-control"
            name="password"
            id="login-password"
            v-model="password"
            required
            type="password"
          />
        </div>
        <div class="row" style="margin-top:12px;">
          <button class="btn purple-btn btn-shadow offset-lg-4 col-lg-2">
            <FontAwesomeIcon icon="user" />&nbsp;&nbsp;Login
          </button>
        </div>
        <div class="row" v-show="error">
          {{ error }}
        </div>
      </div>
    </form>
    <ForgotPasswordModal />
    <div class="vld-parent">
      <loading
        :active.sync="isLoading"
        :can-cancel="false"
        :is-full-page="fullPage"
      ></loading>
    </div>
  </div>
</template>

<script>
import { userService } from "../../_services";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
export default {
  components: {
    ForgotPasswordModal: () => import("./ForgotPasswordModal"),
    FontAwesomeIcon,
    Loading,
  },
  data() {
    return {
      isLoading: false,
      fullPage: true,
      username: "",
      password: "",
      submitted: false,
      returnUrl: "",
      error: "",
    };
  },
  created() {
    // userService.logout();
    this.returnUrl = this.$route.query.returnUrl || "/clients";
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    handleSubmit(e) {
      this.submitted = true;
      const { username, password } = this;

      if (!(username && password)) {
        return;
      }
      this.isLoading = true;

      userService.login(username, password).then(
        (response) => {
          if (response.data) {
            var token = response.data.token;
            localStorage.setItem("token", token);
            this.$store.commit("setIsLoggedIn", true);
            this.isLoading = false;
            this.$router.push("/clients");
          }
        },
        (error) => {
          console.log("logging error");
          console.log(error.response);
          this.isLoading = false;
          console.log(typeof(error));
          if(error.response.data.status === 401 )
          {
            this.error =  "Failed to login. Either your username or password was incorrect.";
          }
          else if(error.response.data.status === 400)
          {
              this.error = "Your account has been deactivated. Please Contact Us for more details."
          }
          else
          {
              this.error = "Something went wrong. Please try again later or Contact Us if this continues."
          }


        }
      );
    },
  },
};
</script>

<style></style>
