<template>
<div>
    <header>
    <SecondNavBar/>
    <LandingBox/>
    </header>
    
</div>
  
</template>

<script>
import SecondNavBar from '../../components/LandingPage/SecondNavBar'
import LandingBox from '../../components/LandingPage/LandingBox'
export default {
    components:{
        SecondNavBar,
        LandingBox
    },
    created(){
        if(this.$store.state.isLoggedIn){
            this.$router.push('/clients')
        }
    }
}
</script>

<style>

</style>