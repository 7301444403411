<template>
  <div class="row">
    <nav
      class="col-12 col-md-12 shadow navbar navbar-expand-lg navbar-light"
      id="main-nav-bar"
    >
      <div class="col-6 col-md-3 col-lg-4">
        <a class="navbar-brand" id="brand-nav-bar"
          ><router-link to="/"
            ><img
              src="../../assets/logo.png"
              class="img-fluid"
              alt="home for life logo"/></router-link
        ></a>
      </div>
      <div class="col-2 col-md-1 col-lg-3 offset-3 offset-md-8 offset-lg-1">
        <button
          @click="toggleNav()"
          class="navbar-toggler mt-1"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
      </div>

      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav ml-md-auto">
          <router-link to="/">
            <li class="nav-item">
              <button class="nav-link margin-link btn border-btn">Home</button>
            </li>
          </router-link>
          <a href="mailto:info@homeforlifedesign.com">
            <li class="nav-item">
              <button
                class="nav-link margin-link btn border-btn"
                id="landing-right-link"
              >
                Contact
              </button>
            </li>
          </a>
        </ul>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  data() {
    return {
      toggled: false,
    };
  },
  methods: {
    toggleNav: function() {
      this.toggled = !this.toggled;
    },
  },
};
</script>

<style></style>
