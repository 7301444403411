// config.js
export const config = {
  apiUrl:
    process.env.VUE_APP_API_URL || 'https://hflapi.azurewebsites.net/api',
  reportUrl:
    process.env.VUE_APP_REPORT_URL || 'https://hflweb.azurewebsites.net/',
  contentfulEnvironmentName:
    process.env.VUE_APP_CONTENTFUL_ENV_NAME || 'master',
  contentfulAccessToken:
    process.env.VUE_APP_CONTENTFUL_ACCESS_TOKEN ||
    'm5lX0mcz97LQsT-OSWSn5M6HGbUZnfGMN0r8yBr-w7g',
  contentfulSpace: 'e6p3qu1ezvxn',
};
