import Vue from 'vue'
import VueRouter from 'vue-router'
import LandingPage from '../views/Home/LandingPage'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Default',
    component: LandingPage
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import(/* webpackChunkName: "register" */ '../views/Home/Register.vue'),
    props: true
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import(/* webpackChunkName: "profile" */ '../views/Account/Profile.vue')
  },
  {
    path: '/terms',
    name: 'Terms',
    component: () => import(/* webpackChunkName: "terms" */ '../views/Home/Terms.vue')
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: () => import(/* webpackChunkName: "privacy" */ '../views/Home/Privacy.vue')
  },
  {
    path: '/clients',
    name: 'Clients',
    props: true,
    component: () => import(/* webpackChunkName: "clients" */ '../views/Clients/Clients.vue')
  },
  {
    path: '/create-client',
    name: 'CreateClient',
    props: true,
    component:() => import(/* webpackChunkName: "createclient" */ '../views/Clients/CreateClient.vue')
  },
  {
    path: '/clients/:id',
    name: 'ClientDetails',
    component:() => import(/*webpackChunkName: "clientdetails" */ '../views/Clients/ClientDetails.vue')
  },
  {
    path: '/clients/:id/create-room',
    name: 'CreateRoom',
    props: true,
    component:() => import(/*webpackChunkName: "addorupdateroom" */ '../views/Clients/AddOrUpdateRoom.vue')
  },
  {
    path: '/clients/:id/rooms/:roomId',
    name: 'EditRoom',
    props: true,
    component: () => import(/*webpackChunkName: "addorupdateroom" */ '../views/Clients/AddOrUpdateRoom.vue')
  },

  // {
  //   path: '/schedule',
  //   name: "Schedule",
  //   component: () => import(/*webpackChunkName: "schedule" */ '../views/Account/Schedule.vue')
  // },
  {
    path: '/admin-dashboard',
    name: "AdminDashboard",
    component: () => import(/*webpackChunkName: "admindashboard" */ '../views/Admin/AdminDashboard.vue')
  },
  // {
  //   path: '/admin-dashboard/payments',
  //   name: "AdminPayments",
  //   component: () => import(/*webpackChunkName: "adminpayments" */ '../views/Admin/AdminPayments.vue')
  // },
  {
    path: '/admin-dashboard/failure-alerts',
    name: "AdminFailureAlerts",
    component: () => import(/*webpackChunkName: "adminfailurealerts" */ '../views/Admin/AdminFailureAlerts.vue')
  },
  {
    path: '/admin-dashboard/partners',
    name: 'AdminPartnerOrganizations',
    component: () => import(/*webpackChunkName: "adminpartners" */ '../views/Admin/AdminPartnerOrganizations.vue')
  },
  {
    path: '/admin-dashboard/deleted-items',
    name: 'DeletedItems',
    component: () => import (/*webpackChunkName: "deleteditems" */ '../views/Admin/DeletedItems.vue')
  },

  {
    path: '/admin-dashboard/room-properties',
    name: 'AdminRoomProperties',
    component: () => import(/*webpackChunkName: "adminroomproperties" */ '../views/Admin/AdminRoomProperties.vue')
  },
  {
    path: '/admin-dashboard/partners/:id/users',
    name: 'PartnerUsers',
    component: () => import(/*webpackChunkName: "partnerusers" */ '../views/Admin/AdminPartnerUsers.vue')
  },
  {
    path: '/admin-dashboard/room-recommendations',
    name: 'AdminRoomRecommendations',
    component: () => import(/*webpackChunkName: "adminroomrecommendations" */ '../views/Admin/AdminRoomRecommendations.vue')
  },
  {
    path: '/my-organization',
    name: "MyOrganization",
    component: () => import(/*webpackChunkName: "myorganization" */ '../views/Manager/MyOrganization.vue')
  },
  {
    path: '/faq',
    name: 'FAQ',
    component: () => import(/*webpackChunkName: "FAQ" */ '../views/Home/FAQ.vue')
  },
  {
    path: '*',
    name: "404",
    component: () => import(/*webpackChunkName: "404" */ '../views/Home/404.vue')
  }

 ]

 const router = new VueRouter({
  routes,
  mode: 'history'
})

router.beforeEach((to, from, next) => {
  // redirect to login if trying to access restricted page
  const publicPages = ['/', '/register', '/terms', '/privacy'];
  const adminPages = ['/admin-dashboard']

  const authRequired = !publicPages.includes(to.path);
  const tokenInQueryString = to.query.token;

  if (tokenInQueryString) {
    localStorage.setItem('token', tokenInQueryString);
  }

  const viewAsUser = localStorage.getItem('ViewAsUserAccessToken');
  const viewAsUserSessionToken = sessionStorage.getItem('ViewAsUserAccessToken');
  if(viewAsUser != null && viewAsUserSessionToken == null) {
    localStorage.removeItem('ViewAsUserAccessToken');
    sessionStorage.setItem('ViewAsUserAccessToken', viewAsUser);
  }

  const loggedIn = sessionStorage.getItem('viewAsUserAccessToken')
    ?? localStorage.getItem('token');

  const adminRequired = adminPages.includes(to.path);
  const role = localStorage.getItem("Role");

  if(authRequired && !loggedIn){
    return next({
      path: '/',
      query: {returnUrl: to.path}
    });
  }
  if(adminRequired && role != "Admin"){
    return next({
      path: '/',
      query: {returnUrl: to.path}
    });
  }
  next();
})

export default router
