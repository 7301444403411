<template>
  <div>
      <div class="row">
          <div class="col-lg-7 offset-lg-3">
               <h5 id="login-leftcol-header" class="purple-title text-center"><strong>MOBILE & SOFTWARE SOLUTIONS</strong> <br/> <span id="login-green-text"><small><strong>FOR HOME ASSESSMENTS</strong></small></span></h5>
          </div>
      </div>
      <div class="row">
          <div class="col-lg-8 offset-lg-3">
              <img src='../../assets/left_col_image.png' class="img-fluid" alt="computer showing home for life software"/>
          </div>
      </div>
      <div class="row" style="margin-top:30px; margin-bottom:20px;">
          <h1 class="col-lg-12 offset-lg-4" style="color:#b4d88c !important;"><strong>Get Started Today</strong></h1>
      </div>
      <div class="row" style="margin-top:15px;">
          <div class="col-lg-5 offset-lg-4">
              <router-link :to="{name: 'Register', params: {showForm: false}}">
                    <button class="btn btn-primary btn-shadow" style="margin-bottom:15px;width:100%;"><strong><FontAwesomeIcon icon="users"/>&nbsp;&nbsp;Enter My Registration Code </strong></button>
              </router-link>
          </div>          
      </div>
      <div class="row" style="margin-top:50px;">
          <p class="offset-lg-2 col-lg-9">*For Hospitals, Therapy Organizations, Rehabilitation Facilities, & Home Health Agencies</p>
      </div>

  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default {
    components:{
        FontAwesomeIcon
    }
}
</script>

<style>

#login-green-text{
    color: #b4d88c;
    letter-spacing: 4px;
}


</style>