import axios from 'axios';
import {config} from '../../config';

export const roomService = {
 getRoomPropertyDefinitionsByRoomName,
 getRoomRecommendationDefinitionsByRoomName,
 getRoomById,
 createRoom,
 createRoomName,
 deleteRoom,
 deleteRoomImage,
 deleteRoomGrabBar,
 getRoomImages,
 editRoom,
 getAllClientRooms,
 getGrabBars,
 saveImages,
 saveRoomGrabBar
};

let baseQueryUrl = config.apiUrl + "/query";
let roomCommandUrl = config.apiUrl + "/command/clients";
let roomQueryUrl = config.apiUrl + "/query/clients";


async function getRoomById(clientId, roomId){
    clientId = Number(clientId);
    const token = getToken();
    return await axios.get(roomQueryUrl + `/${clientId}/rooms/${roomId}`, {
        headers:{
            'Authorization': 'Bearer ' + token
        }
    })
}

async function saveImages(clientId, roomId, formData){
    clientId = Number(clientId);
    const token = getToken();
        return await axios.post(roomCommandUrl + `/${clientId}/rooms/${roomId}/images`, formData, {
            headers:{
                'Authorization': 'Bearer ' + token
            }
        })
}

async function saveRoomGrabBar(clientId, roomId, roomGrabBarModel){
    clientId = Number(clientId);
    roomGrabBarModel.roomId = roomId;
    const token = getToken();
    return await axios.post(roomCommandUrl + `/${clientId}/rooms/${roomId}/roomgrabbars`, roomGrabBarModel, {
        headers:{
            'Authorization': 'Bearer ' + token
        }
    })

}


async function createRoom(roomProperties){
    const token = getToken();
    return await axios.post(roomCommandUrl + `/${roomProperties.clientId}/rooms`, roomProperties, {
        headers:{
            'Authorization': 'Bearer ' + token
        }
    })
}

async function createRoomName(room){
    const token = getToken();
    return await axios.post(roomCommandUrl + `/${room.clientId}/rooms/${room.roomType}`, room, {
        headers:{
            'Authorization': 'Bearer ' + token
        }
    })
}

async function editRoom(roomProperties, roomId){
    const token = getToken();
    return await axios.put(roomCommandUrl + `/${roomProperties.clientId}/rooms/${roomId}`, roomProperties, {
        headers:{
            'Authorization': 'Bearer ' + token
        }
    })
}


async function deleteRoom(roomId, clientId){
    clientId = Number(clientId);
    const token = getToken();
    return await axios.delete(roomCommandUrl + `/${clientId}/rooms/${roomId}`, {
        headers:{
            'Authorization': 'Bearer ' + token
        }
    })
}

async function getAllClientRooms(clientId){
    clientId = Number(clientId);
    const token = getToken();
    return await axios.get(roomQueryUrl + `/${clientId}/rooms`, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    })
}


async function deleteRoomImage(clientId, roomId, pictureId){
    clientId = Number(clientId);
    const token = getToken();
    return await axios.delete(roomCommandUrl + `/${clientId}/rooms/${roomId}/photos/${pictureId}`, {
        headers:{
            'Authorization': 'Bearer ' + token
        }
    })
}

async function getRoomImages(clientId, roomId){
    clientId = Number(clientId);
    const token = getToken();
    return await axios.get(roomQueryUrl + `/${clientId}/rooms/${roomId}/photos`, {
        headers:{
            'Authorization': 'Bearer ' + token
        }
    })
}

async function getRoomPropertyDefinitionsByRoomName(roomName){
    const token = getToken();
    return await axios.get(`${baseQueryUrl}/definitions/properties/${roomName}`, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    })
}

async function getRoomRecommendationDefinitionsByRoomName(roomName){
    const token = getToken();
    return await axios.get(`${baseQueryUrl}/definitions/recommendations/${roomName}`, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    })
}

async function getGrabBars(clientId, roomId) {
    const token = getToken();
    return await axios.get(roomQueryUrl + `/${clientId}/rooms/${roomId}/roomgrabbars`, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    })
}

async function deleteRoomGrabBar(clientId, roomId, grabBarId) {
    const token = getToken();
    return await axios.delete(roomCommandUrl + `/${clientId}/rooms/${roomId}/roomgrabbars/${grabBarId}`, {
        headers:{
            'Authorization': 'Bearer ' + token
        }
    })
}

function getToken() {
  const viewAsUserAccessToken = sessionStorage.getItem('ViewAsUserAccessToken');

  if(!viewAsUserAccessToken) return localStorage.getItem('token')

  return viewAsUserAccessToken;
}
