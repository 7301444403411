import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'bootstrap'
import { TablePlugin } from 'bootstrap-vue'
import Toast from 'vue-toastification'
import "vue-toastification/dist/index.css"
import { library } from '@fortawesome/fontawesome-svg-core'
import { faSave, faMinus, faCheckCircle, faEnvelope, faCaretDown, faArrowLeft, faPlus, faFileDownload, faInfo, faUser, faUsers, faLock } from '@fortawesome/free-solid-svg-icons'
library.add(faSave)
library.add(faPlus)
library.add(faMinus)
library.add(faCheckCircle)
library.add(faEnvelope)
library.add(faCaretDown)
library.add(faArrowLeft)
library.add(faFileDownload)
library.add(faInfo)
library.add(faUser)
library.add(faUsers)
library.add(faLock)
Vue.use(Toast,{
  transition: "Vue-Toastification__bounce",
  maxToasts: 20,
  newestOnTop: true
});


Vue.use(TablePlugin)

Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
